import { Navigate, useSearchParams, type RouteSectionProps, useNavigate } from "@solidjs/router";
import {
  createEffect,
  createResource,
  type JSXElement,
  Match,
  Suspense,
  Switch,
  type Component,
  batch,
} from "solid-js";
import { createStore } from "solid-js/store";
import { useIsIdentityConnecting } from "@core/domains/identity/hooks/useIsIdentityConnecting";
import styles from "./AcceptInviteScreen.module.css";
import { useWire } from "@core/wire";
import { urls } from "@core/lib/urls";
import { SkipToContent } from "@core/components/cta/SkipToContent";
import { LandingPageNavbar } from "@core/marketing/components/LandingPageNavbar";
import { GradientBorder } from "@core/domains/chat/prompt/components/EditorFocusedAnimation";
import { SecondaryCTA } from "@core/components/cta/SecondaryCTA";
import { TbRefresh } from "solid-icons/tb";
import { Spinner } from "@core/components/_original/Spinner";
import { getRequestClient, APIError } from "@repo/client";
import { CustomEvents } from "@core/ui";

export enum TokenError {
  INVALID = "INVALID",
  NOT_FOUND = "NOT_FOUND",
  EXPIRED = "EXPIRED",
  ALREADY_ACCEPTED = "ALREADY_ACCEPTED",
  UNKNOWN = "UNKNOWN",
}

const validateToken = (token: string | string[] | undefined): TokenError | undefined => {
  if (Array.isArray(token)) return TokenError.INVALID;
  if (!token) return TokenError.NOT_FOUND;
  return undefined;
};

export const AcceptCollectionInviteScreen: Component<RouteSectionProps> = () => {
  const wire = useWire();
  const isConnecting = useIsIdentityConnecting();
  const isGuest = wire.services.auth.isGuest;
  const [params] = useSearchParams();
  const [state, setState] = createStore({
    token: undefined as string | undefined,
    error: undefined as TokenError | undefined,
  });

  createEffect(() => {
    const error = validateToken(params.token);
    setState({
      token: error ? undefined : (params.token as string),
      error,
    });
  });

  return (
    <>
      <SkipToContent />
      <LandingPageNavbar static />

      <main id="main-content">
        <Switch>
          <Match when={state.error}>{(error) => <AcceptInviteError refetch={() => {}} error={error()} />}</Match>
          <Match when={isConnecting()}>
            <AcceptInviteCard
              title={
                <>
                  <Spinner />
                  Checking invite
                </>
              }
              subtitle="Please wait..."
            />
          </Match>
          <Match when={isGuest()}>
            <Navigate href={urls.signUp(state.token, "link")} />
          </Match>
          <Match when={state.token}>
            <AcceptInviteGated token={state.token as string} />
          </Match>
        </Switch>
      </main>
    </>
  );
};

// This component should only be rendered if the user is logged in
// with a non-guest account and the token is valid
const AcceptInviteGated: Component<{ token: string }> = (props) => {
  const wire = useWire();
  const client = getRequestClient(wire.services.auth.token);

  const [data, { refetch }] = createResource(
    () => props.token,
    async (token): Promise<{ error?: TokenError | string; orgId?: string }> => {
      try {
        const res = await client.controlplane.AcceptCollectionsSecretLink(token);
        switch (res.code) {
          case "not_found":
            return { error: TokenError.NOT_FOUND };
          case "ok":
            wire.services.collections.setCollection(res.data);
            CustomEvents.collectionTreeUpdated.dispatch();
            return { orgId: res.data.id };
        }
      } catch (error) {
        if (error instanceof APIError && error.code === "not_found") {
          return { error: TokenError.NOT_FOUND };
        }
        return { error: TokenError.UNKNOWN };
      }
      return { error: TokenError.UNKNOWN };
    },
  );
  return (
    <Suspense
      fallback={
        <AcceptInviteCard
          title={
            <>
              <Spinner />
              Accepting invite
            </>
          }
          subtitle="Please wait..."
        />
      }
    >
      <Switch>
        <Match when={data()?.error && data()?.error === TokenError.ALREADY_ACCEPTED}>
          <Navigate href={"/"} />
        </Match>
        <Match when={data()?.error}>{(error) => <AcceptInviteError refetch={refetch} error={error()} />}</Match>
        <Match when={data()?.orgId}>{(orgId) => <Navigate href={urls.collection(orgId())} />}</Match>
      </Switch>
    </Suspense>
  );
};

export const AcceptInviteCard: Component<{ title: JSXElement; subtitle: string; actions?: JSXElement }> = (props) => {
  return (
    <div class={styles["error-card"]}>
      <div class={styles["error-card__title"]}>{props.title}</div>
      <div class={styles["error-card__subtitle"]}>{props.subtitle}</div>

      {props.actions}
      <GradientBorder />
    </div>
  );
};

export const AcceptInviteError: Component<{ error: TokenError | string; refetch: () => void }> = (props) => {
  const title = () => {
    switch (props.error) {
      case TokenError.NOT_FOUND:
        return "This invitation was not found.";
      case TokenError.INVALID:
        return "This invite is invalid";
      case TokenError.EXPIRED:
        return "This invite has expired";
      case TokenError.ALREADY_ACCEPTED:
        return "You've already accepted this invite";
      default:
        return "Something went wrong";
    }
  };

  const subtitle = () => {
    switch (props.error) {
      case TokenError.NOT_FOUND:
      case TokenError.INVALID:
        return "This can occur when the invite was already accepted or if it has been revoked. If you think the invite should still exist please make sure you copied the invite link completely.\nContact the sender if this issue persists.";
      case TokenError.EXPIRED:
        return "Please contact the sender to get a new invite link.";
      case TokenError.ALREADY_ACCEPTED:
        return "We'll redirect you to the organization you've been invited to in 5 seconds.";
      default:
        return "Double check the invite link or try again.\nIf the error persists, please contact the sender or our support team.";
    }
  };

  const navigate = useNavigate();

  return (
    <AcceptInviteCard
      title={title()}
      subtitle={subtitle()}
      actions={
        <Switch>
          <Match when={props.error === TokenError.ALREADY_ACCEPTED || props.error === TokenError.NOT_FOUND}>
            <div class={styles["error-card__actions"]}>
              <div aria-hidden />
              <SecondaryCTA
                data-test-id="already-accepted-redirect-to-home"
                accessiblePrefix="Or click to "
                label="Redirect me right away"
                onClick={() => navigate("/")}
              />
            </div>
          </Match>
          <Match when={props.error === TokenError.UNKNOWN}>
            <div class={styles["error-card__actions"]}>
              <div aria-hidden />
              <SecondaryCTA
                icon={TbRefresh}
                data-test-id="unknown-error-retry"
                accessiblePrefix="Click to "
                label="Retry"
                onClick={props.refetch}
              />
            </div>
          </Match>
        </Switch>
      }
    />
  );
};
